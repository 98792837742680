import React from 'react';
import { BackgroundVideo } from '../../components/background-video/BackgroundVideo';
import './homepage.scss';
import { CardsAndVideoSection } from '../../components/cards-and-video/CardsAndVideo';

export const Homepage: React.FC = () => {

    return <main className='homepage'>
            <BackgroundVideo title='We create powerful digital experiences for positive global impact'/>
            <CardsAndVideoSection />
        </main>

}