import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ReferencialProvider } from './providers/ReferencialContext';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReferencialProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReferencialProvider>
  </React.StrictMode>
);
