import React from 'react';
import './menu.scss';
import { MenuItem as MUIMenuItem, Menu as MUIMenu} from '@mui/material';
import { Button } from '../button/Button';
import classnames from 'classnames';
import { Link } from '../link/Link';
import { MenuButtonPropsType } from '../../models/MenuButtonPropsType';

export interface MenuProps {
    id: string;
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: (event: HTMLDivElement | MouseEvent) => void;
    menuItems?: Array<any>;
    menuListProps?: object;
    buttonProps?: MenuButtonPropsType,
    menuItemClasses?: string;
    children?: React.ReactNode;
}

export const Menu: React.FC<MenuProps> = ({
    id,
    open,
    anchorEl,
    onClose,
    menuItems,
    menuListProps,
    buttonProps,
    menuItemClasses,
    children
}) => {
    
    return <MUIMenu 
            id={id} 
            open={open} 
            onClose={onClose}
            anchorEl={anchorEl}
            MenuListProps={menuListProps}
            classes={{root: 'dark-theme menu', paper: 'menu-paper', list: 'menu-list'}}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {children ? 
                    children : 
                    menuItems && menuItems?.map((menuItem) => {
                        return <Link key={menuItem.id} classes={{root: 'link'}} href={menuItem?.url}><MUIMenuItem classes={{root: classnames('menu-item', menuItemClasses)}} disableRipple>{menuItem?.title}</MUIMenuItem></Link>
                    })}
                    {buttonProps?.buttonLabel && <MUIMenuItem classes={{root: classnames('menu-item', menuItemClasses)}} disableRipple><Button classes={{root: classnames('button','button--accent-primary')}} size="medium" text={buttonProps?.buttonLabel} endIcon={buttonProps?.buttonEndIcon} startIcon={buttonProps?.buttonStartIcon} disableRipple onClick={buttonProps?.buttonClick} href={buttonProps?.buttonUrl}></Button></MUIMenuItem>
                }
            </MUIMenu>
}