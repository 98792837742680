import React, {
    createContext,
    useContext,
    useState,
  } from 'react';
  
  const useReferencialService = () => {
    const [closeDrawer, setCloseDrawer] = useState(false);
    const [active, setActive] = useState<number>(-1);
    const [activeFilter, setActiveFilter] = useState<string | undefined>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [activeCardTitle, setActiveCardTitle] = useState<string | undefined>('');

    return {
        closeDrawer,
        setCloseDrawer,
        active,
        setActive,
        activeFilter,
        setActiveFilter,
        currentPage,
        setCurrentPage,
        activeCardTitle,
        setActiveCardTitle,
    };
  };
  
  export const ReferencialContext = createContext<ReturnType<
    typeof useReferencialService
  > | null>(null);
  
  export const ReferencialProvider = ({
    children,
  }: {
    children: React.ReactNode;
  }) => {
    const Referencials = useReferencialService();
  
    return (
      <ReferencialContext.Provider value={Referencials}>
        {children}
      </ReferencialContext.Provider>
    );
  };
  
  export const useReferencials = () => useContext(ReferencialContext);
  