import React from 'react';
import './work-with-us-page.scss';
import { Button } from '../../components/button/Button';

export const WorkWithUsPage: React.FC = () => {
    return <main className='work-with-us-page'>
                <h1 className='work-with-us-page__title'>Work with us</h1>
                <section className='work-with-us-page__information'>
                    <p className='information__main-paragraph'>Requesting a project with us is the start of a partnership that will guide an idea from a plan to a production and all the way to a functional and memorable user experience.</p>
                    <p className='information__sub-paragraph'>Collaboration with the development team is essential to correctly define the project goals and features. Having a solid plan from the start will help the project run smoothly and lead to a successful outcome.</p>
                    <div>
                        <span className='information__sub-paragraph'><strong>The main things to consider before deciding what type of project you want to create are:</strong></span>
                        <ul className='information__key-points'>
                            <li>Who is your audience?</li>
                            <li>What is your key message?</li>
                            <li>Why does this project type fit your idea best?</li>
                            <li>When do you need the final product?</li>
                            <li>Where will the project be used or shown?</li>
                            <li>How will your users access the project?</li>
                        </ul>
                    </div>
                    <span className='information__sub-paragraph'>To start the process contact a member of the Virtual Reality Team.</span>
                    {/* <Button classes={{root: 'button button--primary', sizeLarge: 'button--large'}} size='large' text='Contact us' disableRipple href='mailto:gva_virtual_reality@icrc.org'></Button> */}
                </section>
    </main>
}