import React, { useEffect, useState } from 'react';
import { Card } from '../card/Card';
import { cardList, landscapeCardList } from '../../data/data';
import './cards-and-video.scss';
import { VideoDrawer } from '../video-drawer/VideoDrawer';
import { useReferencials } from '../../providers/ReferencialContext';
import { LandscapeCardPropsType } from '../../models/LandscapeCardPropsType';

export const CardsAndVideoSection: React.FC = () => {

    const Referencial = useReferencials();
    const [filteredLandscapeCardList, setFilteredLandscapeCardList] = useState(landscapeCardList);

    useEffect(() => {        
            const temp = landscapeCardList.filter((card: LandscapeCardPropsType) => {
                if(Referencial?.activeCardTitle && card?.tag?.includes(Referencial?.activeCardTitle)) return card;
            });
            setFilteredLandscapeCardList(temp);
    },[Referencial?.activeCardTitle]);


    return <section className='cards-video-section' id="our-products">
            <h2 className='cards-video-section__title'>Our products</h2>
             <div className='card-list'>
                {cardList.map((card, index) => {
                    return  <div key={card.id} className='card__wrapper'>
                                <Card index={index} surtitle={card?.surtitle} imageProps={card?.imageProps} content={card?.content} tag={card?.tag}/>
                                <div className='video-drawer__mobile'>{Referencial?.active === index && <VideoDrawer videoData={filteredLandscapeCardList}/>}</div>
                            </div>
                })}
            </div>
            <div className='video-drawer__desktop'>
                <VideoDrawer videoData={filteredLandscapeCardList}/>
            </div>
        </section>
}