import React from 'react';
import './video-content.scss';

interface VideoProps {
  dataVideo?: string;
  title?: string;
  paragraph?: string;
}

const VideoContent: React.FC<VideoProps> = ({ dataVideo, title, paragraph }) => {
  return (
    <div className='video-content'>
      <div className='video-content__aspect-ratio'>
        {dataVideo && <iframe title='vimeo-player' src={dataVideo} allowFullScreen></iframe>}
        <h3 className='video-content__title'>{title}</h3>
        <p className='video-content__paragraph'>
            {paragraph}
        </p>
      </div>
    </div>
  );
};

export { VideoContent };
