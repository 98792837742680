import React, { useEffect, useState } from 'react';
import { LandscapeCard } from '../../components/landscape-card/LandscapeCard';
import { featuredProjectsFilters, landscapeCardList } from '../../data/data';
import './featured-projects-page.scss';
import { Pagination } from '@mui/material';
import { useReferencials } from '../../providers/ReferencialContext';
import { LandscapeCardPropsType } from '../../models/LandscapeCardPropsType';
import { Filter } from '../../components/filter/Filter';
import { Link } from 'react-router-dom';
import { VideoModal } from '../../components/video-modal/VideoModal';
import { kebabCase } from '../../helpers/kebabCase';

export const FeaturedProjectsPage: React.FC = () => {
    const Referencial = useReferencials();
    const [filteredLandscapeCardList, setFilteredLandscapeCardList] = useState(landscapeCardList);
    const landscapeCardCount = filteredLandscapeCardList.length;
    const cardsPerPage = 8;
    const currentPage = Referencial?.currentPage ? Referencial?.currentPage : 1;
    const [openForCard, setOpenForCard] = useState(false);

    useEffect(() => {
        if(Referencial?.activeFilter !== '')  {
            const temp = landscapeCardList.filter((card: LandscapeCardPropsType) => {
                if(Referencial?.activeFilter && card?.tag?.includes(Referencial?.activeFilter)) return card;
            });
            setFilteredLandscapeCardList(temp);
        }    
        else {
            setFilteredLandscapeCardList(landscapeCardList);
        }
    }, [Referencial?.activeFilter])

    const handlePagination = (event: React.ChangeEvent<unknown>, page: number) => {
        Referencial?.setCurrentPage(page); 
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })   
    };


    const handleThumbnailForCard = () => {
        setOpenForCard(true);
    }

    const handleScroll = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })  
    }

    const renderCards = () => {
        const temp = filteredLandscapeCardList?.slice((currentPage - 1) * cardsPerPage, (currentPage - 1) * cardsPerPage + cardsPerPage);    
        return temp.map((card) => {            
            if (card?.type === 'case study') return  <Link key={card?.id} to={`/article/${kebabCase(card?.surtitle)}`} className='landscape-card__link' onClick={handleScroll}>
                                                        <LandscapeCard surtitle={card?.surtitle} imageProps={card?.imageProps} tags={card?.tag} type={card?.type} />
                                                    </Link>
            else if(card?.type === 'video') return  <div key={card?.id} className='landscape-card__wrapper'>
                            <LandscapeCard surtitle={card?.surtitle} imageProps={card?.imageProps} tags={card?.tag} type={card?.type} onClick={handleThumbnailForCard} />
                            <VideoModal open={openForCard} vimeoSource={card?.vimeo} setOpen={setOpenForCard}/>
                        </div>
            else return <LandscapeCard key={card?.id} surtitle={card?.surtitle} imageProps={card?.imageProps} tags={card?.tag} type={card?.type} />

        })
    };

    return <main className='featured-projects-page'>
            <h1 className='featured-projects-page__title'>Our featured projects</h1>
            <div className='featured-projects-page__filters'>
                {featuredProjectsFilters.map((filter) => {
                    return <Filter key={filter?.id} title={filter.title} titleId={filter.titleId} />
                } )}
            </div>
            <div className='landscape-card-list'>
                    {renderCards()}
            </div>
            {(landscapeCardCount / cardsPerPage) > 1 && <Pagination count={Math.ceil(landscapeCardCount / cardsPerPage)} classes={{root: 'pagination'}} onChange={(e, page) => handlePagination(e, page)} />}
        </main>

}