import React from 'react';
import { MainHeader } from '../main-header/MainHeader';
import './header.scss';

interface HeaderProps {
    modifier?: string;
}

export const Header: React.FC<HeaderProps> = ({
    modifier
}) => {

    return <header className={`header ${modifier}`}>
            <MainHeader />
         </header>
}