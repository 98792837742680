import React from 'react';
import { Link as MUILink } from '@mui/material';
import './link.scss';

export interface LinkProps {
    classes?: object;
    color?: string;
    underline?: 'always' | 'hover' | 'none';
    variant?: 'body1' | 'body2' | 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2';
    children?: React.ReactNode;
    href?: string;
    onClick?: (Element?: any) => void;
    target?: string;
    rel?: string;
    download?: string | boolean;
}

export const Link:React.FC<LinkProps> = ({
    classes,
    color,
    underline,
    variant,
    children,
    href,
    onClick,
    target,
    rel,
    download
}) => {
    return (
        <MUILink 
            classes={classes} 
            color={color} 
            onClick={onClick} 
            underline={underline} 
            variant={variant} 
            href={href}
            target={target}
            rel={rel}
            download={download}
        >{children}</MUILink>
    )
}