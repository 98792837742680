import React, { useRef, useState } from 'react';
import vr from '../../shared/assets/video/vr.mp4';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import './background-video.scss';
export interface BackgroundVideoProps {
    title?: string;
}

export const BackgroundVideo:React.FC<BackgroundVideoProps> = ({
    title
}) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleVideoPlayPause = () => {
        if (isPlaying) {
            videoRef?.current?.pause();
        } else {
            videoRef?.current?.play();
        }
        setIsPlaying(!isPlaying);
    };

    return <div className='background-video__container'>
            <video className='background-video' autoPlay={isPlaying} loop muted ref={videoRef}>
                <source src={vr} type='video/mp4' />
                <track src="captions.vtt" kind="captions" srcLang="en" label="english_captions"></track>
            </video>
            <div className='gradient-overlay'></div>
            <h1 className='background-video__title'>{title}
                <a className='background-video__slide-down' href="#our-products">
                    <ExpandMoreOutlinedIcon />
                </a>
            </h1>
            <button className='background-video__pause-container' onClick={handleVideoPlayPause}>
                <div className='background-video__play-pause-wrapper' aria-label="Play and pause video">
                    {isPlaying ? <PauseIcon className='background-video__play-pause'/> : <PlayArrowIcon className='background-video__play-pause'/>}
                </div>
            </button>
        </div>
}