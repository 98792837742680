import { Card as MUICard, CardMedia as MUICardMedia, CardContent as MUICardContent, Chip } from '@mui/material';
import React from 'react';
import './landscape-card.scss';
import { ImagePropsType } from '../../models/ImagePropsType';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';


export interface LandscapeCardProps {
    surtitle?: string;
    content?: string;
    tags?: Array<string>;
    imageProps?: ImagePropsType,
    type?: string;
    modifier?: boolean;
    onClick?: () => void;
}

export const LandscapeCard: React.FC<LandscapeCardProps> = ({
    surtitle, 
    content,
    imageProps,
    type,
    modifier,
    tags,
    onClick
}) => {    

    return  <MUICard classes={{root: 'landscape-card'}} tabIndex={0} onClick={onClick}>
                <MUICardMedia component='img' alt={imageProps?.alt} src={imageProps?.src} title={imageProps?.title} srcSet={imageProps?.srcSet} classes={{root: 'landscape-card__image'}}></MUICardMedia>
                    <MUICardContent classes={{root: 'landscape-card__text-wrapper'}}>
                        <Chip icon={type === 'video' ? <PlayArrowOutlinedIcon classes={{root: 'landscape-card__chip-icon'}}/> : <ArticleOutlinedIcon  classes={{root: 'landscape-card__chip-icon'}} />} label={type === 'video' ? 'VIDEO' : 'CASE STUDY'} classes={{root: 'landscape-card__chip', label: 'chip__label'}} size='small'/>
                        <div className='landscape-card__surtitle'>{surtitle}</div>
                        {!modifier && content && <div className='landscape-card__content'>{content}</div>}
                        {tags?.map((tag, index) => {
                            const i = index + 1;
                            return <div className='landscape-card__tag' key={i}>{tag}</div>
                        })}
                    </MUICardContent>
            </MUICard>
}