import { Divider } from '@mui/material';
import React from 'react';
import './logo.scss';
import { LogoPropsType } from '../../models/LogoPropsType';
import Union from '../../shared/assets/img/Union.svg';
import VRUnit from '../../shared/assets/img/Virtual Reality Unit.svg';

export interface LogoProps {
    logoProps?: LogoPropsType;
    placeholder?: string;
}

export const Logo: React.FC<LogoProps> = ({
    placeholder,
    logoProps
}) => {
    return <div className='logo-wrapper'>
                    <a className='logo__link' href={`${logoProps?.href}`}><img src={`${logoProps?.src}`} alt={logoProps?.alt} className='logo' title={logoProps?.title} srcSet={logoProps?.srcSet}/></a>
                    {placeholder && 
                        <>
                            <Divider orientation='vertical' flexItem classes={{root: 'logo__divider'}}/>
                            <div className='logo__text'>
                                <a className='logo__link union' href='/'><img src={Union} alt='Union' title='Union'/></a>
                                <a className='logo__link vr-unit' href='/'><img src={VRUnit} alt='Virtual Reality Unit' title='Virtual Reality Unit'/></a>
                            </div>
                        </>}
            </div>
}