import React from 'react';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import './styles/global.scss';
import { Homepage } from './pages/homepage/Homepage';
import { FeaturedProjectsPage } from './pages/featured-projects-page/FeaturedProjectsPage';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ArticlePage } from './pages/article-page/ArticlePage';
import { WorkWithUsPage } from './pages/work-with-us-page/WorkWithUsPage';

function App() {
  const location = useLocation();
  const pathname = location.pathname;
  

  return (
    <div className='App dark-theme' style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', height: '100vh'}}>
      <Header modifier={pathname === '/' ? 'header--homepage' : ''}/>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/featured-projects' element={<FeaturedProjectsPage />} />
        <Route path='/article/:id' element={<ArticlePage />} />
        <Route path='/work-with-us' element={<WorkWithUsPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
