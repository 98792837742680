import React, { useEffect, useState } from 'react';
import { ParagraphCover } from '../../components/paragraph-cover/ParagraphCover';
import './article-page.scss';
import { Link, useParams } from 'react-router-dom';
import { ParagraphCoverType } from '../../models/ParagraphCoverType';
import { VideoContent } from '../../components/video-content/VideoContent';
import { landscapeCardList } from '../../data/data';
import { Button } from '../../components/button/Button';
import { LandscapeCard } from '../../components/landscape-card/LandscapeCard';
import { LandscapeCardPropsType } from '../../models/LandscapeCardPropsType';
import { kebabCase } from '../../helpers/kebabCase';
import { VideoModal } from '../../components/video-modal/VideoModal';

export const ArticlePage: React.FC = () => {

    const params = useParams();
    const [openForCard, setOpenForCard] = useState(false);

    const article = landscapeCardList.filter((card: LandscapeCardPropsType) => kebabCase(card.surtitle) === params.id);

    const tag = article[0].tag[0];    
    const id = article[0].id;

    const articlesByTag = landscapeCardList.filter((card: LandscapeCardPropsType) => (card.tag && card.tag[0] === tag) && card.id !== id);
        
    const handleThumbnailForCard = () => {
        setOpenForCard(true);
    }

    const handleScroll = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return <main className='article-page'>
            {article[0]?.tag.map((tag: string, index: number) => {
                const i = index + 1;
                return <span className='article-page__type' key={i}>{tag}</span>
            })}
            <h1 className='article-page__title'>{article[0]?.surtitle}</h1>
            <VideoContent dataVideo={article[0]?.vimeo}/>
            <article>
                {article[0]?.article?.map((paragraph: ParagraphCoverType, index: number) => {
                    const i = index + 1;
                    return <ParagraphCover key={i} title={paragraph.title} subtitle={paragraph.subtitle} paragraph={paragraph.paragraph} imageProps={paragraph.imageProps} modifier={paragraph.modifier}/>
                })}
            </article>
            <section className='article-page__more-projects'>
                <h3 className='more-projects__title'>More {tag} projects</h3>
                <div className='more-projects__articles'>
                    {articlesByTag.map((article) => {
                        if (article?.type === 'case study') return  <Link key={article?.id} to={`/article/${kebabCase(article?.surtitle)}`} className='landscape-card__link' onClick={handleScroll}>
                            <LandscapeCard surtitle={article?.surtitle} imageProps={article?.imageProps} tags={article?.tag} type={article?.type} />
                        </Link>
                        else if(article?.type === 'video') return  <div key={article?.id}>
                        <LandscapeCard surtitle={article?.surtitle} imageProps={article?.imageProps} tags={article?.tag} type={article?.type} onClick={handleThumbnailForCard} />
                        <VideoModal open={openForCard} vimeoSource={article?.vimeo} setOpen={setOpenForCard}/>
                        </div>
                        else return <LandscapeCard key={article?.id} surtitle={article?.surtitle} imageProps={article?.imageProps} tags={article?.tag} type={article?.type} />
                    })}
                </div>
                <Button href='/featured-projects' classes={{root: 'button button--secondary', sizeLarge: 'button--large'}} size='large' text='See all our projects' disableRipple></Button>
            </section>
        </main>

}