import { Chip } from '@mui/material';
import React from 'react';
import { useReferencials } from '../../providers/ReferencialContext';

interface FilterProps {
    title: string;
    titleId: string;
} 

export const Filter:React.FC<FilterProps> = ({
    title, 
    titleId,
}) => {
    const Referencial = useReferencials();
    const isActive = Referencial?.activeFilter === titleId;

    const handleFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, title: string) => {
        if (isActive) {
            // If this chip is already active, deactivate it
            Referencial?.setActiveFilter('');
        } else {
            // If this chip is not active, activate it
            Referencial?.setActiveFilter(title);
        }
        Referencial?.setCurrentPage(1);
    };

    return  <Chip label={title} classes={{root: `featured-projects-page__filter ${isActive ? 'selected' : ''}`, label: 'filter__label'}} tabIndex={0} onClick={(e) => handleFilter(e, titleId)}/>
}