import React from 'react';
import * as MuiIcons from '@mui/icons-material';

export interface IconProps {
    iconName: string;
    className?: string;
    onClick?: () => void;
}

export const Icon: React.FC<IconProps> = ({
    iconName,
    className,
    onClick
}) => {
    const IconComponent = MuiIcons[iconName as keyof typeof MuiIcons];
    return <IconComponent className={className} onClick={onClick}/>
}