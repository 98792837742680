import React, { useEffect, useState } from 'react';
import './footer.scss';
import footerImage from '../../shared/assets/img/footerimg.svg';
import { Button } from '../button/Button';
import { Link } from '../link/Link';
import { useLocation } from 'react-router-dom';


export const Footer: React.FC = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const [displayBanner, setDisplayBanner] = useState(false);

    useEffect(() => {
        const hasConsent = localStorage.getItem('cookie_consent');
        if (!hasConsent) {
          setDisplayBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookie_consent', 'true');
        setDisplayBanner(false);
    };
    
    const handleReject = () => {
        localStorage.setItem('cookie_consent', 'false');
        setDisplayBanner(false);
    };
    
    return <footer className='footer-wrapper'>
           {!pathname.includes('/work-with-us') && <div className='footer'>
                <img className='footer__image' src={footerImage} alt='Virtual Reality employee' />
                <div className='footer__content-wrapper'>
                    <h2 className='footer__content-title'>
                        Build your experience with us
                    </h2>
                    <div className='footer__content-text'>
                        <span className='content-text__list-info'>We can provide you with:</span>
                        <ul>
                            <li>Flexible and tailored services,</li>
                            <li>Extensive understanding of humanitarier contexts</li>
                            <li>Collaborative and agile approach</li>
                            <li>A rich repository of content</li>
                            <li>A proven track record.</li>
                        </ul>
                    </div>
                    <Button classes={{root: 'button button--primary', sizeLarge: 'button--large'}} size='large' text='Work with us' disableRipple href='/work-with-us'></Button>
                </div>
            </div>}
            <nav className='footer__sub-nav'>
                <div className='sub-nav__info'>
                    <Link classes={{root: 'footer__link mr-16'}} href='https://www.icrc.org/en/copyright-and-terms-use' target='_blank' rel='noreferrer'>Copyright ICRC 2024</Link>
                    <Link classes={{root: 'footer__link'}}href='https://www.icrc.org' target='_blank' rel='noreferrer'>icrc.org</Link>
                </div>
                <div  className='sub-nav__links'>
                    <Link classes={{root: 'footer__link mr-16'}} href='https://www.icrc.org/en/copyright-and-terms-use' target='_blank' rel='noreferrer'>Legal mentions</Link>
                    <Link classes={{root: 'footer__link mr-16'}}href='https://www.icrc.org/en/privacy-policy' target='_blank' rel='noreferrer'>Privacy policy</Link>
                </div>
            </nav>

            {displayBanner && <div id='cookie-consent-banner'>
                <div className='cookie-consent-banner__inner'>
                    <div className='cookie-consent-banner__copy'>
                    <div className='cookie-consent-banner__header'>THIS WEBSITE USES COOKIES</div>
                    <div className='cookie-consent-banner__description'>We use tracking cookies to understand how you use the product and help us improve it. Please accept cookies to help us improve.</div>
                </div>
                <div className='cookie-consent-banner__actions'>
                    <button onClick={() => handleAccept()} className='cookie-consent-banner__cta'>
                        OK
                    </button>
                    <button onClick={() => handleReject()} className='cookie-consent-banner__cta cookie-consent-banner__cta--secondary'>
                        Decline
                    </button>
                    </div>
                </div>
            </div>}
        </footer>
}