import { Card as MUICard, CardMedia as MUICardMedia, CardContent as MUICardContent } from '@mui/material';
import React from 'react';
import './card.scss';
import { ImagePropsType } from '../../models/ImagePropsType';
import { useReferencials } from '../../providers/ReferencialContext';


export interface CardProps {
    surtitle: string;
    content?: string;
    imageProps?: ImagePropsType,
    index: number;
    tag?: string;
}

export const Card: React.FC<CardProps> = ({
    surtitle, 
    content,
    imageProps,
    index,
    tag
}) => {    

    const Referencial = useReferencials();

    const handleActive = () => {
        Referencial?.setActive(index);
        Referencial?.setActiveCardTitle(tag);
        Referencial?.setCloseDrawer(false);
    }

    return <div 
                className='card-link' 
                onClick={handleActive} 
                tabIndex={0} 
                role="button"  
                onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleActive();
                        }
                    }}
                >
            <MUICard classes={{root: `card ${index === Referencial?.active ? 'active' : ''}`}}>
                <MUICardMedia component='img' alt={imageProps?.alt} src={imageProps?.src} title={imageProps?.title} srcSet={imageProps?.srcSet} classes={{root: 'card__image'}}></MUICardMedia>
                    <MUICardContent classes={{root: 'card__text-wrapper'}}>
                        <div className='card__surtitle'>{surtitle}</div>
                        <div className='card__content'>{content}</div>
                    </MUICardContent>
            </MUICard>
        </div>
}