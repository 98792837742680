import img from '../shared/assets/img/video360.svg';
import img2 from '../shared/assets/img/multiplayer.svg';
import img3 from '../shared/assets/img/vrpresentation.svg';
import img4 from '../shared/assets/img/computergenerated.svg';
import landscapeImage5 from '../shared/assets/img/guards2.svg';
import ICRCLogo from '../shared/assets/img/ICRC logo.svg';
import paragraphImage from '../shared/assets/img/birdview.svg';
import paragraphImage2 from '../shared/assets/img/guards.svg';
import paragraphImage3 from '../shared/assets/img/soldiers.svg';
import mod from '../shared/assets/img/mod.svg';
import mod2 from '../shared/assets/img/mod2.svg';
import mod3 from '../shared/assets/img/mod3.svg';
import mds from '../shared/assets/img/mds.svg';
import mds2 from '../shared/assets/img/mds2.svg';
import mds3 from '../shared/assets/img/mds3.svg';
import congo360 from '../shared/assets/img/congo360.svg';
import congo3602 from '../shared/assets/img/congo3602.svg';
import congo3603 from '../shared/assets/img/congo3603.svg';

export const cardList = [
    {
        id: 1,
        surtitle:'360° videos',
        tag: '360° video',
        content:'Immersive experiences capturing a panoramic view of the real world',
        imageProps: {
            src: img,
            title: '360° videos',
            srcSet: '',
            alt: 'Immersive experiences capturing a panoramic view of the real world'
        }
    },
    {
        id: 2,
        surtitle:'VR Presentations',
        tag: 'Virtual Reality',
        content:'Content, pitches or educational material in a virtual environment for enhanced engagement',
        imageProps: {
            src: img3,
            title: 'VR Presentations',
            srcSet: '',
            alt: 'Content, pitches or educational material in a virtual environment for enhanced engagement'
        }
    },
    {
        id: 3,
        surtitle:'Multiplayer experiences',
        tag: 'Multiplayer experiences',
        content:'Engaging and responsive virtual scenarios allowing multiple users to interact with the enviromement or objects',
        imageProps: {
            src: img2,
            title: 'Multiplayer experiences',
            srcSet: '',
            alt: 'Engaging and responsive virtual scenarios allowing multiple users to interact with the enviromement or objects'
        }
    },
    {
        id: 4,
        surtitle:'Computer generated videos',
        tag:'Computer generated video',
        content:'Virtual experiences created through computer-generated imagery for simulation or training',
        imageProps: {
            src: img4,
            title: 'Computer generated videos',
            srcSet: '',
            alt: 'Virtual experiences created through computer-generated imagery for simulation or training'
        }
    }
];

export const links = [
    {
        id: 1,
        url: '/',
        text: 'WHAT WE DO'
    },
    {
        id: 2,
        url: '/featured-projects',
        text: 'FEATURED PROJECTS'
    },
];

export const featuredProjectsFilters = [
    {
        id: 1,
        title: 'Computer generated videos',
        titleId: 'Computer generated video',
        clicked: false,
    },
    {
        id: 2,
        title: '360° videos',
        titleId: '360° video',
        clicked: false,
    },
    {
        id: 3,
        title: 'Virtual Reality',
        titleId: 'Virtual Reality',
        clicked: false,
    },
    {
        id: 4,
        title: 'Multiplayer experiences',
        titleId: 'Multiplayer experiences',
        clicked: false,
    }
];

export const logoProps = {
    src: ICRCLogo,
    srcSet: '',
    alt: 'ICRC Logo',
    title: 'ICRC Logo',
    href: '/',
};

export const paragraphSection = [
    {
        title: 'Creating an accessible educational content for diverse audiences',
        subtitle: 'OUR GOAL',
        paragraph:'The objective was to produce a nuanced and visually compelling video, based on an Urban Warfare Report, that conveyed complex information clearly and resonated with a diverse, multilingual audience, such as young people and military groups, including non-state armed groups.',
        imageProps: {
            src: paragraphImage,
            title: 'Bird view',
            srcSet: '',
            alt: 'Bird view of buildings'
        }
    },
    {
        title: 'Collaborative and iterative process to deliver the best possible result',
        subtitle: 'OUR APPROACH',
        paragraph:'Our work involved working closely with the Communication team, exchanging ideas and feedback to ensure alignment with the video objectives and quality standards. \nThe main challenge was to accurately portray scenes in alignment with the script while managing practical constraints such as time, resources, and neutrality. We used slow-motion effects to enhance the visual appeal & ensured that all elements, such as military uniforms and weaponry, did not represent any specific real-world group.',
        imageProps: {
            src: paragraphImage2,
            title: 'Soldiers guarding the base',
            srcSet: '',
            alt: 'Soldiers guarding the base station'
        },
        modifier: 'article-cover--right'
    },
    {
        title: 'Unique opportunity to engage the audience while conveying key messages',
        subtitle: 'The Results',
        paragraph:'The Team\'s expertise in ARMA 3 and 3D dynamic narration stood out, ensuring the content was tailored and impactful.\nThe video received a significant number of views in a short period, surpassing the viewership of other videos that had been online for much longer.',
        imageProps: {
            src: paragraphImage3,
            title: 'Soldiers',
            srcSet: '',
            alt: 'Armed soldiers'
        }
    },
];

export const landscapeCardList = [
    {
        id: 1,
        surtitle:'Reducing Civilian Harm in Urban Warfare',
        tag:['Computer generated video'],
        imageProps: {
            src: landscapeImage5,
            title: 'Employee playing VR',
            srcSet: '',
            alt: 'Employee testing out video games on VR'
        },
        type:'case study',
        article: paragraphSection,
        vimeo: 'https://player.vimeo.com/video/1013226402?h=2327eb7b7e'
    },
    {
        id: 2,
        surtitle:'Management of the Dead',
        tag:['Virtual Reality'],
        imageProps: {
            src: mod2,
            title: 'Employee testing out VR',
            srcSet: '',
            alt: 'Employee testing out VR'
        },
        article: [
            {
                subtitle: 'OUR GOAL',
                paragraph:'This intention of this project was to create an immersive tool to help train people conducting body collection in multiple scenarios.  The tool was aimed at posing realistic situations and environments at trainees to help them gain understanding of what they may face in real life situations.  This includes weapon contamination, respect of the dead and procedures vital to identification.   ',
                imageProps: {
                    src: mod,
                    title: 'Medical staff on the field',
                    srcSet: '',
                    alt: 'Medical staff on the field'
                }
            },
            {
                subtitle: 'OUR APPROACH',
                paragraph:'We worked closely with both internal and external forensics experts to produce a useful and interesting training tool.  The team research real places and real situations and create an experience that reflects the reality of management of the dead.  Exploring key aspects of management of the dead was important in creating a realistic scenario and an environment that is immersive as possible.',
                imageProps: {
                    src: mod2,
                    title: 'Employee testing out VR',
                    srcSet: '',
                    alt: 'Employee testing out VR'
                },
                modifier: 'article-cover--right'
            },
            {
                subtitle: 'THE RESULTS',
                paragraph:'The final project allows users to be virtually placed in a dangerous environment or a situation that would otherwise be very difficult to recreate.  Trainers can view what the user is doing and give real time feedback and advice.  This is a great conversation starter and helps trainers share knowledge with clear and demonstrable scenarios.  ',
                imageProps: {
                    src: mod3,
                    title: 'Mine check',
                    srcSet: '',
                    alt: 'Staff doing mine check'
                },
            },
        ],
        type:'case study',
        vimeo: 'https://player.vimeo.com/video/1014202037?h=13c04c0128'
    },
    {
        id: 3,
        surtitle:'Multiplayer Detention Simulation',
        tag:['Multiplayer experiences'],
        imageProps: {
            src: mds,
            title: 'Quarters',
            srcSet: '',
            alt: 'Quarters'
        },
        article: [
            {
                subtitle: 'OUR GOAL',
                paragraph:'The project was created to allow users to conduct virtual visits to different types of detention facilities each with their own features and objectives.  This gives the opportunity to trainers to easily introduce new delegates to the challenges and conditions that they will face in real life visits.',
                imageProps: {
                    src: mds,
                    title: 'Quarters',
                    srcSet: '',
                    alt: 'Quarters'
                }
            },
            {
                subtitle: 'OUR APPROACH',
                paragraph:'In all our projects we research real life locations to identify what to include in the environment to get as close to real situations as possible.  Understanding the real-life locations as much as possible is extremely important in creating believable and useful projects.  Collecting real world stories of detainees is also key in providing realistic scenarios to trainees.',
                imageProps: {
                    src: mds2,
                    title: 'Virtual Reality team',
                    srcSet: '',
                    alt: 'Virutal Reality team working on different topics'
                },
                modifier: 'article-cover--right'
            },
            {
                subtitle: 'THE RESULTS',
                paragraph:'A great system for exploration, role play and interaction that allows users to make a virtual visit to a realistic detention facility.  Learn about the lives of detainees and role play situations that give an insight into the issues real detainees may face.',
                imageProps: {
                    src: mds3,
                    title: 'VR Simulation of employees working',
                    srcSet: '',
                    alt: 'VR Simulation of employees working'
                },
            },
        ],
        type:'case study',
        vimeo: 'https://player.vimeo.com/video/1013223143?h=555b279e95'
    },
    {
        id: 4,
        surtitle:'Democratic Republic of the Congo 360 video',
        tag:['360° video'],
        article: [
            {
                subtitle: 'OUR GOAL',
                paragraph:'360 Videos are a great way of bringing the reality of the field to donors and staff who cannot visit these places.  Being able to put someone into another place just by wearing a VR headset or stepping into a projection dome can be an incredibly immersive experience.  Our aim is to deliver a slice of the field to viewers of these videos.',
                imageProps: {
                    src: congo360,
                    title: 'ICRC staff on field in Congo',
                    srcSet: '',
                    alt: 'ICRC staff on field in Congo'
                }
            },
            {
                subtitle: 'OUR APPROACH',
                paragraph:'At the heart of our approach is to spend time with staff and beneficiaries to really understand what their lives are like and try and give a taste of that to the viewers.  We spend time exploring the area to get amazing footage that helps tell the story of each location.  Conducting interviews with local people really helps provide understanding of the situations people face and show how the ICRC has provided support.',
                imageProps: {
                    src: congo3602,
                    title: 'Local people of Congo',
                    srcSet: '',
                    alt: 'Local people of Congo'
                },
                modifier: 'article-cover--right'
            },
            {
                subtitle: 'THE RESULTS',
                paragraph:'The final videos really Immerse the viewer completely in the environment and allow them to experience a location they could not visit.  They can see the lives of beneficiaries and ICRC staff in the field and get a taste of the situations they face.  This can be emotional to see the challenges that people face around the world but also show how the ICRC can help drive change.',
                imageProps: {
                    src: congo3603,
                    title: 'ICRC staff filming',
                    srcSet: '',
                    alt: 'ICRC staff filming'
                },
            },
        ],
        imageProps: {
            src: congo3602,
            title: 'Local people of Congo',
            srcSet: '',
            alt: 'Local people of Congo'
        },
        type:'case study',
        vimeo: 'https://player.vimeo.com/video/1013224480?h=474ff76635'
    },
];