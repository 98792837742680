import { Dialog } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './video-modal.scss';


interface VideoModalProps {
    open: boolean;
    vimeoSource?: string | null;
    setOpen: (Element: boolean) => void;
}

export const VideoModal: React.FC<VideoModalProps> = ({
    open,
    vimeoSource,
    setOpen
}) => {
    return <Dialog open={open} onClose={() => setOpen(false)} classes={{paper: 'video-modal'}}>
            <div className='video-modal__close-wrapper'>
                <div className='video-modal__close-circle'>
                    <CloseIcon classes={{ root: 'video-modal__close-icon' }} onClick={() => setOpen(false)} fontSize='small'/>
                </div>
            </div>
            <div className='video-modal__player-wrapper'>
                {vimeoSource && <iframe title='vimeo-player' src={vimeoSource} allowFullScreen></iframe>}
            </div>
        </Dialog>
}