import React, { useState } from 'react';
import './video-drawer.scss';
import CloseIcon from '@mui/icons-material/Close';
import { LandscapeCard } from '../landscape-card/LandscapeCard';
import { useReferencials } from '../../providers/ReferencialContext';
import { LandscapeCardPropsType } from '../../models/LandscapeCardPropsType';
import { VideoModal } from '../video-modal/VideoModal';
import { Link } from 'react-router-dom';
import { kebabCase } from '../../helpers/kebabCase';
import { Button } from '../button/Button';
import { Chip } from '@mui/material';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
interface VideoDrawerProps {
    videoData?: Array<LandscapeCardPropsType>;
}

export const VideoDrawer: React.FC<VideoDrawerProps> = ({
    videoData
}) => {

    const Referencial = useReferencials();
    const [open, setOpen] = useState(false);
    const [openForCard, setOpenForCard] = useState(false);

    const handleDrawer = () => {
        Referencial?.setCloseDrawer(true);
        Referencial?.setActive(-1);
    }

    const handleThumbnail = () => {
        setOpen(true);
    }

    const handleThumbnailForCard = () => {
        setOpenForCard(true);
    }

    const handleScroll = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })  
    }

    return <div className={`video-drawer__wrapper ${Referencial?.closeDrawer || !Referencial?.activeCardTitle ? 'hidden' : ''}`}>
            <div className='video-drawer__container'>
                <div className='video-drawer__title'>Featured {Referencial?.activeCardTitle} projects</div>
                <div className='video-drawer'>
                    <CloseIcon classes={{ root: 'video-drawer__close' }} onClick={handleDrawer}/>
                    {videoData?.map((card: LandscapeCardPropsType, index: number) => {
                        if (index === 0) {
                            if(card?.type === 'case study') return <div key={card?.id} className='card__wrapper'>
                                <Link to={`/article/${kebabCase(card?.surtitle)}`} className='card__link' onClick={handleScroll}>
                                    <img className='background-video' src={card?.imageProps?.src} alt={card?.imageProps?.alt} title={card?.imageProps?.src}/>
                                    <div className='card__info'>
                                        <Chip icon={<ArticleOutlinedIcon  classes={{root: 'card__chip-icon'}} />} label={'CASE STUDY'} classes={{root: 'card__chip', label: 'chip__label'}} size='small'/>
                                        <span className='card__title'>{card?.surtitle}</span>
                                    </div>
                                    <div className='gradient-overlay'>
                                    </div>
                                </Link>
                                    </div>
                            else return <div key={card?.id} className='card__wrapper'>
                                <img 
                                    className='background-video' 
                                    src={card?.imageProps?.src} 
                                    alt={card?.imageProps?.alt} 
                                    title={card?.imageProps?.src} 
                                    onClick={handleThumbnail} 
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleThumbnail();
                                        }
                                    }}
                                    tabIndex={0}
                                    role="button"
                                />
                                <div className='card__info'>
                                    <Chip icon={<PlayArrowOutlinedIcon classes={{root: 'card__chip-icon'}}/>} label={'VIDEO'} classes={{root: 'card__chip', label: 'chip__label'}} size='small'/>
                                    <span className='card__title'>{card?.surtitle}</span>
                                </div>
                                <div className='gradient-overlay'></div>
                                <VideoModal open={open} vimeoSource={card?.vimeo} setOpen={setOpen}/>
                            </div>
                        }
                    }
                    )}
                    <div className='video-drawer__featured-projects'>
                        {videoData?.map((card: LandscapeCardPropsType, index: number) => {
                            if(index !== 0 && index < 4) {
                                if (card?.type === 'case study') return <Link key={card?.id} to={`/article/${kebabCase(card?.surtitle)}`} onClick={handleScroll}><LandscapeCard modifier surtitle={card?.surtitle} imageProps={card?.imageProps} content={card?.content} type={card?.type} /></Link>
                                else return <div key={card?.id}>
                                                <LandscapeCard modifier surtitle={card?.surtitle} imageProps={card?.imageProps} content={card?.content} type={card?.type} onClick={handleThumbnailForCard} />
                                                <VideoModal open={openForCard} vimeoSource={card?.vimeo} setOpen={setOpenForCard}/>
                                            </div>
                            }
                        })}
                        <Button href='/featured-projects' classes={{root: 'button button--secondary', sizeLarge: 'button--large'}} size='large' text='See all our projects' disableRipple></Button>
                    </div>
                </div>
            </div>
    </div>
}