import React from 'react';
import './paragraph-cover.scss';
import { ImagePropsType } from '../../models/ImagePropsType';

export interface ParagraphCoverProps  {
    imageProps?: ImagePropsType;
    modifier?: string;
    subtitle?: string;
    title?: string;
    paragraph?: string;
}

export const ParagraphCover: React.FC<ParagraphCoverProps> = ({
    imageProps,
    modifier,
    title,
    paragraph,
    subtitle,
}) => {
    return <div className={`article-cover ${modifier}`}>
                {imageProps && <figure className="article-cover__figure">
                    <img src={imageProps.src} alt={imageProps.alt} title={imageProps.title} srcSet={imageProps.srcSet} className="article-cover__image"/>
                    <figcaption className="article-cover__image-caption">{imageProps.figCaption}</figcaption>
                </figure>}
                <div className="article-cover__text-wrapper">
                    {subtitle && <h4 className="article-cover__subtitle">{subtitle}</h4>}
                    {title && <h3 className="article-cover__title">{title}</h3>}
                    {paragraph && <p className="article-cover__content">{paragraph}</p>}
                </div>
        </div>
}
